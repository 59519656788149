import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import Layout from '../components/layout'


function Donate(){
    return(
        <Layout>
            Donate
        </Layout>
    )
}

export default Donate